import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { parseImageUrl } from '@conradlin/notabase/src/utils'



export default ({ data }) => {
  const { posts: { title, tags, publish_date, html, url, slug, desc, color, cover_image } } = data
  let coverimageURL = parseImageUrl(cover_image[0], 1000, slug)
  const new_html = html
    .replace(/Acorn/g,'<a href="https://www.acorns.com/invite/AXWMJE">Acorn</a>')
    .replace(/Bluehost/g,'<a href="https://www.bluehost.com/track/finallygtd/">Bluehost</a>')
    .replace(/Digital Ocean/g,'<a href="https://cloud.digitalocean.com/account/referrals?i=2a8b9a">Digital Ocean</a>')
    .replace(/Dreamhost/g,'<a href="https://mbsy.co/3h7SGD">Dreamhost</a>')
    .replace(/Fathom/g,'<a href="https://usefathom.com/ref/3K36IZ">Fathom</a>')
    .replace(/Google Workspace/g,'<a href="https://referworkspace.app.goo.gl/Va56">Google Workspace</a>')
    .replace(/Lose it/g,'<a href="http://fbuy.me/v/david_27758">Lose it</a>')
    .replace(/NameCheap/g,'<a href="http://namecheap.pxf.io/jKGgZ">NameCheap</a>')
    .replace(/Namecheap/g,'<a href="http://namecheap.pxf.io/jKGgZ">Namecheap</a>')
    .replace(/Pretty Links/g,'<a href="https://prettylinks.com?aff=finallygtd">Pretty Links</a>')
    .replace(/Todoist/g,'<a href="https://todoist.com/r/david_diem_usevfr">Todoist</a>')
    .replace(/Trello/g,'<a href="https://trello.com/daviddiem1/recommend">Trello</a>')
    .replace(/Zoho/g,'<a href="https://go.zoho.com/Kez">Zoho</a>')

  return (
    <Layout>
      <div id = "main">
      <img 
                alt={`${title} cover image`}
                style={{ width: '50%' }}
                src={coverimageURL}
              />
        <div>{tags && tags.join(', ')}</div> 
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: new_html }} />
      </div>
      <div>
      
    </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    posts(slug: { eq: $slug }) {
      html
      title
      tags
      publish_date{
        startDate(formatString: "YYYY-MMM-DD", fromNow: false)
      }
      url
      desc
      color
      cover_image
      slug
    }
  }
`
